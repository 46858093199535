import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {IConfirmTokenRequest} from '../interfaces/IApi';
import {lastValueFrom} from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class ApiService {
    private headers = new HttpHeaders().set('content-type', 'application/json');

    constructor(private http: HttpClient) {
    }

    getGruppo(event_id: string, group_id: string): Promise<any> {
        return new Promise((resolve, reject) => {
            lastValueFrom(this.http.post<any>(environment.URL_API + '/app/users/getGruppo', {data: {event_id: event_id, group_id: group_id}}, {headers: this.headers})).then(res => {
                resolve(res.data);
            }).catch(err => {
                reject(err);
            });
        });
    }

    checkPostoGruppi(event_id: string, group_id: string): Promise<any> {
        return new Promise((resolve, reject) => {
            lastValueFrom(this.http.post<any>(environment.URL_API + '/app/users/checkGruppoAvailable', {data: {event_id: event_id, id: group_id}}, {headers: this.headers})).then(res => {
                if (res.data.code === 'code/ok_available') {
                    resolve('available');
                } if (res.data.code === 'code/ok_full') {
                    resolve('full');
                }else {
                    resolve('error');
                }
            }).catch(err => {
                console.error(err);
                resolve(false);
            });
        });
    }

    /**
     * Conferma se il token è valido
     * @param data {data: {event_id: string, token_reg: string}}
     */
    confirmToken(data: IConfirmTokenRequest): Promise<any> {
        return lastValueFrom(this.http.post<IConfirmTokenRequest>(environment.URL_API + '/app/users/userCheckToken', data, {headers: this.headers})).then((res) => {
            return res.data;
        }).catch((err) => {
            console.error('Errore', err);
            return Promise.reject(err);
        });
    }

    userSignUp(data: any): Promise<any> {
        return lastValueFrom(this.http.post<any>(environment.URL_API + '/app/users/userSignUp', data, {headers: this.headers})).then(res => {
            return res.data;
        });
    }

    userSignUpBonuomo(data: any): Promise<any> {
        return new Promise((resolve, reject) => {
            lastValueFrom(this.http.post<any>(environment.URL_API + '/app/users/bonuomo/userSignUpBonuomo', data, {headers: this.headers})).then(res => {
                resolve(res.data);
            }).catch(err => {
                reject(err);
            });
        });

    }

    updateUnsubscribe(data: any): Promise<any> {
        return lastValueFrom(this.http.post<any>(environment.URL_API + '/app/users/updateUnsubscribe', data, {headers: this.headers})).then(res => {
            return res.data;
        });
    }

    /**
     * Funzione per ottenere il codice univoco per Netcomm2024
     * @param data
     */
    getCode(data: any): Promise<any>{
        const headers = new HttpHeaders().set('content-type', 'application/json');
        return lastValueFrom(this.http.post<any>(environment.URL_API + '/app/netcomm2024/getCode', data, {headers: this.headers})).then(res => {
            return res.data;
        });
    }

    /**
     * Funzione per registrare l'utente tramite open form per l'evento Netcom2024
     * @param data
     */
    userSignUpNetcomm2024(data: any): Promise<any> {
        return new Promise((resolve, reject) => {
            lastValueFrom(this.http.post<any>(environment.URL_API + '/app/netcomm2024/userReg', {data: data}, {headers: this.headers})).then(res => {
                resolve(res.data);
            }).catch(err => {
                reject(err);
            });
        });

    }

    /**
     * Funzione per registrare la demo all'utente per l'evento Netcom2024
     * @param data
     */
    userChooseDemoNetcomm2024(data: any): Promise<any> {
        return new Promise((resolve, reject) => {
            lastValueFrom(this.http.post<any>(environment.URL_API + '/app/netcomm2024/chooseDemo', {data: data}, {headers: this.headers})).then(res => {
                resolve(res.data);
            }).catch(err => {
                reject(err);
            });
        });
    }

    /**
     * Funzione per prenotare la demo all'utente per il post evento Netcomm2024
     * @param data
     */
    userChooseDemoPostNetcomm2024(data: any): Promise<any> {
        return new Promise((resolve, reject) => {
            lastValueFrom(this.http.post<any>(environment.URL_API + '/app/netcomm2024/chooseDemoPostEvent', {data: data}, {headers: this.headers})).then(res => {
                resolve(res.data);
            }).catch(err => {
                reject(err);
            });
        });
    }

    /**
     * Funzione per registrare l'utente tramite open form per l'evento Netcom2024 Post Evento
     * @param data
     */
    userSignUpNetcomm2024PostEvent(data: any): Promise<any> {
        return new Promise((resolve, reject) => {
            lastValueFrom(this.http.post<any>(environment.URL_API + '/app/netcomm2024/userRegPostEvent', {data: data}, {headers: this.headers})).then(res => {
                resolve(res.data);
            }).catch(err => {
                reject(err);
            });
        });

    }

    /**
     * Funzione per registrare l'utente tramite open form per l'evento Mif2024
     * @param data
     */
    userSignUpMif2024(data: any): Promise<any> {
        return new Promise((resolve, reject) => {
            lastValueFrom(this.http.post<any>(environment.URL_API + '/app/mif2024/userReg', {data: data}, {headers: this.headers})).then(res => {
                resolve(res.data);
            }).catch(err => {
                reject(err);
            });
        });

    }

    /**
     * Funzione per registrare la masterclass e i parcheggi all'utente per l'evento Mif2024
     * @param data
     */
    userUpdateMif2024(data: any): Promise<any> {
        return new Promise((resolve, reject) => {
            lastValueFrom(this.http.post<any>(environment.URL_API + '/app/mif2024/userUpdate', {data: data}, {headers: this.headers})).then(res => {
                resolve(res.data);
            }).catch(err => {
                reject(err);
            });
        });
    }

    /**
     * Funzione per recuperare le masterclass per l'evento Mif2024
     * @param eventId
     */
    getMasterclassesMif2024(eventId: string): Promise<any> {
        return new Promise((resolve, reject) => {
            lastValueFrom(this.http.post<any>(environment.URL_API + '/app/mif2024/getMasterclasses', {data: {eventId}}, {headers: this.headers})).then(res => {
                resolve(res.data);
            }).catch(err => {
                reject(err);
            });
        });
    }

    /**
     * Funzione per verificare che l'email precompilata non sia già presente nell'evento del mif
     * @param eventId
     * @param email
     */
    checkOpenFormMif2024(eventId: string, email: string){
        return new Promise((resolve, reject) => {
            lastValueFrom(this.http.post<any>(environment.URL_API + '/app/mif2024/checkOpenFormMif', {data: {eventId, email}}, {headers: this.headers})).then(res => {
                resolve(res.data);
            }).catch(err => {
                reject(err);
            });
        });
    }

    /**
     * Funzione per l'update dell'utente per il pre evento del Mif2024
     * @param data
     */
    userUpdatePreEventMif2024(data: any): Promise<any> {
        return new Promise((resolve, reject) => {
            lastValueFrom(this.http.post<any>(environment.URL_API + '/app/mif2024/userUpdatePreEvent', {data: data}, {headers: this.headers})).then(res => {
                resolve(res.data);
            }).catch(err => {
                reject(err);
            });
        });
    }

    /**
     * Funzione per la registrazione da open form al pre evento del Mif2024
     * @param data
     */
    userRegPreEventMif2024(data: any): Promise<any> {
        return new Promise((resolve, reject) => {
            lastValueFrom(this.http.post<any>(environment.URL_API + '/app/mif2024/userRegPreEvent', {data: data}, {headers: this.headers})).then(res => {
                resolve(res.data);
            }).catch(err => {
                reject(err);
            });
        });
    }

    /**
     * Chiamata per l'invio del consenso al comparire in video e foto
     * @param eventId
     * @param userId
     * @param consent
     */
    photoVideoConsentsMif2024(eventId: string, userId: string, consent: boolean){
        return new Promise((resolve, reject) => {
            lastValueFrom(this.http.post<any>(environment.URL_API + '/app/mif2024/photoVideoConsents', {data: {eventId, userId, consent}}, {headers: this.headers})).then(res => {
                resolve(res.data);
            }).catch(err => {
                reject(err);
            });
        });
    }

    /**
     * Funzione per registrare il risultato della survey dell'utente
     * @param data
     */
    submitSurvey(data: any): Promise<any> {
        return new Promise((resolve, reject) => {
            lastValueFrom(this.http.post<any>(environment.URL_API + '/app/survey/submitSurvey', {data: data}, {headers: this.headers})).then(res => {
                resolve(res.data);
            }).catch(err => {
                reject(err);
            });
        });

    }

    userSignUpBiomedica2024(data: any): Promise<any> {
        return new Promise((resolve, reject) => {
            lastValueFrom(this.http.post<any>(environment.URL_API + '/app/users/biomedica2024/userSignUp', data, {headers: this.headers})).then(res => {
                resolve(res.data);
            }).catch(err => {
                reject(err);
            });
        });

    }

    /**
     * Controlla se il form è ancora disponibile per la compilazione, oppure è scaduto
     * @param data
     */
    checkFormLimit(data: any): Promise<any> {
        return new Promise((resolve, reject) => {
            lastValueFrom(this.http.post<any>(environment.URL_API + '/app/users/checkFormLimit', data, {headers: this.headers})).then(res => {
                resolve(res.data);
            }).catch(err => {
                reject(err);
            });
        });

    }

    /**
     * Submit del form di biomedica 2025
     * @param data
     */
    userSubmitBiomedica2025(data: any): Promise<any> {
        return new Promise((resolve, reject) => {
            lastValueFrom(this.http.post<any>(environment.URL_API + '/app/users/biomedica2025/userSubmit', data, {headers: this.headers})).then(res => {
                resolve(res.data);
            }).catch(err => {
                reject(err);
            });
        });

    }
}
