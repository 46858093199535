import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {DefaultErrorComponent} from './core/default-error/default-error.component';

const hostName = window.location.host;

/**
 * Verifica se il dominio specificato è presente nel nome host corrente.
 * Considera anche la porta se presente nel nome host.
 *
 * @param {string} domain - Il dominio da verificare.
 * @returns {boolean} Ritorna true se il dominio corrisponde al nome host corrente, altrimenti false.
 */
const isOnDomain = (domain: string) => {
    return RegExp('(' + domain + ')|(' + domain + '):(\d+)?$').test(hostName);
};

/**
 * Seleziona la configurazione di routing in base al dominio corrente.
 *
 * Se il dominio corrente corrisponde a 'localhost', restituisce un array di oggetti che definiscono le rotte
 * per diversi percorsi, altrimenti restituisce una rotta di default per gestire gli errori.
 *
 * @returns {Array<object>} Un array di oggetti che definiscono le rotte, oppure un array con una singola rotta di default.
 */
const rootSelector: any = () => {
    if (isOnDomain('localhost')) {
        // console.log('match localhost');
        return [
            {path: 'tim-demo', pathMatch: 'prefix', loadChildren: () => import('./landings/tim-demo/tim-demo.module').then(m => m.TimDemoModule)},
            {path: 'bonuomo', pathMatch: 'prefix', loadChildren: () => import('./landings/bonuomo-9-maggio-2024/bonuomo9-maggio2024.module').then(m => m.Bonuomo9Maggio2024Module)},
            {path: 'sap-2024', loadChildren: () => import('./landings/sap2024/sap2024.module').then(m => m.Sap2024Module)},
            {path: 'biomedica-2024', pathMatch: 'prefix', loadChildren: () => import('./landings/biomedica-sales-meeting2024/biomedica-sales-meeting2024.module').then(m => m.BiomedicaSalesMeeting2024Module)},
            {path: 'biomedica-2025', pathMatch: 'prefix', loadChildren: () => import('./landings/biomedica2025/biomedica2025.module').then(m => m.Biomedica2025Module)},
        ];
    } else if (isOnDomain('form.studioditostobonuomo.it') || isOnDomain('dev.form.studioditostobonuomo.it')) {
        return [{path: 'creators-e-diritti-digitali', pathMatch: 'prefix', loadChildren: () => import('./landings/bonuomo-9-maggio-2024/bonuomo9-maggio2024.module').then(m => m.Bonuomo9Maggio2024Module)}];
    } else if (isOnDomain('langing.gevents.it') || isOnDomain('dev.landing.gevents.it')) {
        return [{path: 'tim-demo', pathMatch: 'prefix', loadChildren: () => import('./landings/tim-demo/tim-demo.module').then(m => m.TimDemoModule)}];
    } else if (isOnDomain('mastercardevents.it') || isOnDomain('dev.mastercardevents.it')) {
        return [
            {path: 'sap-2024', loadChildren: () => import('./landings/sap2024/sap2024.module').then(m => m.Sap2024Module)},
        ];
    } else if (isOnDomain('biomedica-italia-salesmeeting.it')) {
        // console.log('match biomedica-italia-salesmeeting.it');
        return [{path: '', pathMatch: 'prefix', loadChildren: () => import('./landings/biomedica2025/biomedica2025.module').then(m => m.Biomedica2025Module)}];
    } else {
        // console.log('no match');
        return [{path: '', pathMatch: 'full', component: DefaultErrorComponent}];
    }
};

const routes: Routes = [
    ...rootSelector(),
    {path: '**', component: DefaultErrorComponent},
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
